#schedule {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  background: white;
}

#schedule tr td:nth-child(n + 2) {
  font-size: 11px;
  cursor: pointer;
}

#schedule tr th,
#schedule tr td {
  position: relative;
  /*
  height temporarily decreased until the covid go away
  height: 46px;
  */
  height: 38px;
  padding: 0 8px;
  text-align: right;
  text-transform: uppercase;
  vertical-align: middle;
  border: #d9d9d9 solid thin;
}

@media only screen and (max-width: 599px) {
  #schedule tr td:nth-child(n + 2),
  #schedule tr th {
    /*
    font-size and padding temporarily decreased until the covid go away
    font-size: 9px;
    padding: 0 6px;
    */
    font-size: 8px;
    padding: 0 4px;
  }
}

#schedule tr th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#schedule tr td:after {
  content: "";
  position: absolute;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  padding: 7px;
  top: 0;
  left: 0;
  font-family: "Material Icons";
  font-size: 16px;
}

#schedule tr td:hover:after {
  background-color: #f3f3f3;
}

#schedule tr td.locked:after {
  content: "\e897";
}

#schedule tr td:not(.locked):hover:after {
  content: "\e897";
}

#schedule tr td.locked:hover:after {
  content: "\e898";
}
